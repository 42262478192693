import React, { useState, useEffect } from 'react';
import Background from '../../assets/fundo.png';
import styles from './styles';

const Home = () => {
    const [progress, setProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');

    useEffect(() => {
        const getPathname = () => {
            const path = window.location.pathname;
            return path.substring(1) || 'redirect'; 
        };

        const source = getPathname();

        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    setLoadingMessage('✅ Concluído!');

                    //FIXOS 82
                    window.location.replace(`https://wlsuperbet.adsrv.eacdn.com/C.ashx?btag=a_6188b_378c_&affid=678&siteid=6188&adid=378&c=${source}`);
                    
                    return 100;
                }
                const diff = 100 / 40;
                const newProgress = Math.min(oldProgress + diff, 100);
                updateLoadingMessage(newProgress);
                return newProgress;
            });
        }, 100); // 100 ms

        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateLoadingMessage = (progress) => {
        if (progress < 40.33) {
            setLoadingMessage('⏳ Carregando página...');
        } else if (progress < 72.66) {
            setLoadingMessage('🔓 Conexão segura...');
        } else if (progress < 85) {
            setLoadingMessage('✅ Concluído!');
        }
    };

    return (
        <div style={styles.backgroundContainer}>
            <img src={Background} alt="Background" style={styles.backgroundImage} />
            <div style={styles.progressContainer}>
                <div className="loading-message" style={styles.loadingMessage}>{loadingMessage}</div>
                <div style={styles.progressBarContainer}>
                    <div style={{ ...styles.progressBar, width: `${progress}%` }} />
                </div>
            </div>
        </div>
    );
};

export default Home;
