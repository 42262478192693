const styles = {
    body: {
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
        overflow: 'hidden' 
    },
    backgroundContainer: {
        position: 'relative', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', 
        width: '100vw', 
        overflow: 'hidden' 
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        display: 'block',
    },
    progressContainer: {
        position: 'absolute',
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        width: '100%', 
        textAlign: 'center'
    },
    progressBarContainer: {
        width: '80%', 
        height: '20px', 
        backgroundColor: '#ccc', 
        borderRadius: '8px', 
        overflow: 'hidden', 
        marginTop: '10px' 
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#c50102', 
        transition: 'width 0.1s linear', 
        width: '100%' 
    },
    loadingMessage: {
        color: '#000', 
        fontSize: '20px', 
        fontWeight: 'bold', 
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)', 
        marginBottom: '10px' 
    }
};

export default styles;
